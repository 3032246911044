<template>
    <div>
        <h3 class="text-center mb-2" v-if="!noHeader">
            Ready to begin filing your tax return?
        </h3>
        <div class="col mx-auto mt-0 pt-0">
            <div class="row buttons_row align-items-end justify-content-center">
                <div class="col-12 col-sm-6 col-md-5 mb-3">
                    <btn
                        @click="$router.push({ name: 'Signup' })"
                        block
                        class="transition-3d-hover"
                    >
                        Get Started
                    </btn>
                </div>
                <div class="col-12 col-sm-6 col-md-5 mt-0 pt-0 mb-3">
                    <span class="row mb-1 ml-1 font-italic text-muted"
                        >Already have an account?</span
                    >
                    <btn
                        @click="$router.push({ name: 'Login' })"
                        block
                        class="transition-3d-hover"
                        color="info"
                    >
                        Sign In
                    </btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        noHeader: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
