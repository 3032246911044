<template>
    <div class="landing-wrapper">
        <t-header />
        <div class="container blog_text blog-post-wrapper">
            <div class="col-md-10 col-lg-8 mx-auto">
                <div class="mb-4">
                    <h1 class="h1">
                        How to file U.S. tax returns as an international student
                    </h1>
                    <p class="helper_text mb-4">
                        All F-1 and J-1 students must complete form 8843, even
                        if they didn't have income. If they did have U.S.
                        income, they are required to file a full U.S. tax
                        return.
                    </p>
                    <p class="blog_author_text">
                        The Thriftax Team
                        <br />
                        Jan 5 2021 · 5 min read
                    </p>
                </div>
                <div class="position-relative">
                    <img
                        class="card-img"
                        :src="getImgUrl('remote_study_2')"
                        alt="Image Description"
                    />
                </div>
                <h3 class="mt-4">Figuring out your residency status</h3>
                <p>
                    If you are on an F-1 or J-1 student visa you are most likely
                    considered a non-resident alien for tax purposes. You might
                    still be considered a US resident alien if you were in the
                    US under an F, J, Q or M visa before your arrival with your
                    current F-1 or J-1 visa. Thriftax will help you figuring out
                    your tax residency status free of charge.
                </p>
                <h3 class="mt-4">
                    Figure out your filing requirement and which forms to file
                </h3>
                <p>
                    U.S. Non-resident aliens may be required to file form
                    1040NR. As a rule of thumb – if you had a U.S. sourced
                    income (salaries, self-employment, scholarships,
                    fellowships, interest, capital gain etc.), you are required
                    to file.
                </p>
                <p>
                    <span class="font-weight-bold">Form 1040NR</span> is the
                    federal tax return form for non-resident aliens. If you have
                    any income originating from a U.S. source, for example
                    wages, scholarships or tips, you'll need to file this form.
                </p>
                <p>
                    <span class="font-weight-bold">Form 8843</span> – If you are
                    a student with an F-1 or J-1 visa you are also required by
                    law to file form 8843. This form is mandatory regardless if
                    you are required to file forms 1040NR-EZ or 1040NR.
                </p>
                <p>
                    <span class="font-weight-bold">State tax return</span> is a
                    state-specific tax return required by some states. Alaska,
                    Florida, Nevada, South Dakota, Texas, Washington, and
                    Wyoming (and in most cases New Hampshire and Tennessee) do
                    not require to file personal tax returns. If you are
                    studying at a college in one of these 9 states and you don’t
                    have income from any other state you may not need to file a
                    state tax return.
                </p>
                <p>
                    Thriftax can help you determine your filing requirement and
                    easily file your tax return forms for as low as $34.90
                    including your 8843 form. If you are only required to file
                    form 8843, Thriftax will help you file and ship the form for
                    you for only $12.90.
                </p>
                <h3 class="mt-4">
                    How to file your federal tax return by yourself
                </h3>
                <p>
                    Generally, we do not recommend filing tax returns by
                    yourself for a few reasons: First, filing yourself can be
                    challenging and time consuming. Second, it may also reduce
                    your tax credit or result in an incorrect filing. However,
                    you may still want to file your tax returns by yourself, in
                    that case follow the steps below:
                </p>
                <ol class="px-4">
                    <li class="mb-2">
                        Determine your tax residency status – Thriftax can help
                        you determining your status free of charge! Simply use
                        Thriftax software and be sure to complete the residency
                        stage. Note: Thriftax only supports tax return filing
                        for non-resident aliens, so if you were able to complete
                        the residency stage and continue the filing process you
                        are deemed a non-resident alien. If you are deemed a
                        resident alien, stop here.
                    </li>
                    <li class="mb-2">F-1 or J-1 students – file form 8843.</li>
                    <li class="mb-2">
                        Figure out if you need to file a tax return form – have
                        a US sourced income? You need to file. Note: U.S. income
                        includes all types of income and not just employment
                        income. If you don’t have a U.S income, stop here.
                    </li>
                    <li class="mb-2">
                        Download the tax forms that you’re required to file and
                        follow the form’s requirements line by line. Do not skip
                        lines unless the line is asking for information
                        irrelevant to you. Some of the lines may require you to
                        make calculations such as adding, subtracting, dividing,
                        or multiplying, it is recommended that you use a
                        calculator to prevent mistakes. Don’t forget to deduct
                        your expenses such as student loan interest, state taxes
                        etc.
                    </li>
                    <li class="mb-2">
                        Depending on your country of residency, you may be
                        eligible for a tax treaty benefit that may increase your
                        tax refund significantly! Use this link or run a search
                        for your country of residency name together with the
                        words “tax treaty” to review and determine your treaty
                        benefits.
                    </li>
                    <li class="mb-2">
                        Upon completion of filing your forms, fill the date and
                        sign in the corresponding fields and ship them to the
                        IRS as follows:
                        <p class="mt-2">
                            If you are not enclosing a payment, mail your forms
                            to:
                        </p>
                        <div class="px-3 pb-3">
                            <p class="p-0 m-0">Department of the Treasury</p>
                            <p class="p-0 m-0">Internal Revenue Service</p>
                            <p class="p-0 m-0">Austin, TX 73301-0215</p>
                            <p class="p-0 m-0">U.S.A.</p>
                        </div>
                        <p class="mt-2">
                            If you are not enclosing a payment, mail your forms
                            to:
                        </p>
                        <div class="px-3 pb-3">
                            <p class="p-0 m-0">Internal Revenue Service</p>
                            <p class="p-0 m-0">P.O. Box 1303</p>
                            <p class="p-0 m-0">Charlotte, NC 28201-1303</p>
                            <p class="p-0 m-0">U.S.A.</p>
                        </div>
                    </li>
                </ol>
                <ReadyToFileBanner />
                <blog-badges />
            </div>
        </div>
        <t-footer />
    </div>
</template>

<script>
import ReadyToFileBanner from '@/components/landings/ready-to-file-banner';
import BlogBadges from './blog-badges';

export default {
    components: {
        ReadyToFileBanner: ReadyToFileBanner,
        BlogBadges,
    },
    methods: {
        getImgUrl(image) {
            var images = require.context('@/assets/img/', false, /\.jpg$/);
            return images('./' + image + '.jpg');
        },
    },
};
</script>
