<template>
    <div class="mt-5">
        <a
            v-for="item in items"
            :key="item"
            class="btn btn-xs btn-soft-secondary mb-1 mx-1"
            href="javascript:;"
            >{{ item }}</a
        >
    </div>
</template>

<script>
export default {
    props: { customItems: Array },
    data() {
        return {
            items: [
                'International Student',
                'International Student Tax Return',
                'International Student Tax Filing',
                'Foreign Student',
                'F-1',
                'J-1',
                'J1',
                'tax return',
                'US tax return',
                'Covid-19',
                'Covid',
                '1040NR',
                '1040NR-EZ',
                'Nonresident tax return',
                'student visa',
                'state tax return',
                'California tax return',
                '8843',
                'tax refund',
                'OPT',
                'academic training',
                '2020 tax return',
                'w-2',
                'w2',
            ],
        };
    },

    created() {
        if (this.customItems && this.customItems.length) this.items = this.customItems;
    },
};
</script>
